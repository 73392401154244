import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--toporder-employee-form"
export default class extends Controller {
  connect() {
    $(this.element).validate({
      rules: {
        'toporder_employee[international_phone_number]': {
          international_phone_number: true
        }
      }
    })
    $(this.element).find('select#toporder_employee_role').on('select2:select',() => {
      this.roleChanged()
    })
    this.roleChanged()
  }

  roleChanged() {
    if($(this.element).find('select#toporder_employee_role').val() == 'Installateur') {
      $('#toporder_employee_installer').prop('checked', true)
      $('.toporder_employee_installer').hide()
    } else {
      $('.toporder_employee_installer').show()
    }
  }
}
