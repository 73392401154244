import { Controller } from "@hotwired/stimulus"
import { TurboToporderTable } from 'shared/turbo_toporder_table';
// Connects to data-controller="index-pages--generic-index-table"
export default class extends Controller {
  connect() {
    if($(this.element).find('table').length > 0) {
      new TurboToporderTable(
        $(this.element).find('table'),
        undefined,
        undefined,
        $(`#${$(this.element).data().mainController}`).get(0).controller
      )      
      $('.menu_export_xlsx').show()
    } else {
      $('.menu_export_xlsx').hide()
    }
  }

  debounceClick(event) {
    event.preventDefault(); // Prevent the default link action

    let link = event.target.closest('a');
    window.open(link.href, "_blank");

    const links = link.parentElement.querySelectorAll('a');
    links.forEach(function(a) {
      a.style.pointerEvents = "none"; // Disable clicking
      a.style.opacity = "0.5"; // Optionally change appearance
    });

    setTimeout(() => {
      links.forEach(function(a) {
        a.style.pointerEvents = "auto"; // Disable clicking
        a.style.opacity = "1"; // Optionally change appearance
      })
    }, 10000); // 10 seconds
  }
}
