import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--tracking-commercial-actions-table"
export default class extends Controller {
  showProspects(e) {
    console.log('bouhhh')
    ajaxPostTurboStream(
      '/admin/commercial_actions/tracked_commercial_actions_prospects',
      {
        prospect_ids: $(e.currentTarget).data().prospectIds,
        sdr: $(e.currentTarget).data().sdr,
        commercial: $(e.currentTarget).data().commercial
      }
    )
  }
}
