import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--dashboard--tracking-commercial-actions"
export default class extends Controller {
  connect() {
    this.reload()
    $(this.element).find('select').on('select2:select', () => {this.reload()})
  }

  reload() {
    let params = {
      status: $('#admin-dashboard__tracking-commercial-actions__select-status').val()
    }
    if($('#admin-dashboard__tracking-commercial-actions__select-prospect-inbound-type').val() != '_') {
      params.inbound_type = $('#admin-dashboard__tracking-commercial-actions__select-prospect-inbound-type').val()
    }
    $(this.element).addClass('loading-card')
    ajaxPostTurboStream(
      '/admin/dashboard/load_tracking_commercial_actions',
      params,
      () => {$(this.element).removeClass('loading-card')},
      false
    )
  }
}
