export function defaultMomentRanges(moment) {
  return {
    all_time: {
      label: "Toutes les dates",
      range: [moment("2020-01-01"), moment().add(1, 'year')]
    },
    today: {
      label: "Aujourd'hui",
      range: [moment(), moment()]
    },
    yesterday: {
      label: "Hier",
      range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
    },
    prev_week: {
      label: "La semaine dernière",
      range: [moment().subtract(7, 'days').startOf('week'), moment().subtract(7, 'days').endOf('week')]
    },
    current_week: {
      label: "La semaine en cours",
      range: [moment().startOf('week'), moment().endOf('week')]
    },
    prev_seven_days: {
      label: "Les 7 derniers jours",
      range: [moment().subtract(6, 'days'), moment()]
    },
    prev_month: {
      label: "Le mois précédent",
      range: [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
    },
    prev_six_months: {
      label: "Les 6 mois précédents",
      range: [moment().subtract(7, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')]
    },
    next_month: {
      label: "Le mois suivant",
      range: [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')]
    },
    current_month: {
      label: "Le mois en cours",
      range: [moment().startOf('month'), moment().endOf('month')]
    },
    thirty_days: {
      label: "Les 30 derniers jours",
      range: [moment().subtract(29, 'days'), moment()]
    },
    next_thirty_days: {
      label: "Les 30 prochains jours",
      range: [moment(), moment().add(29, 'days')]
    },
    current_year: {
      label: "L'année en cours",
      range: [moment().startOf('year'), moment().endOf('year')]
    },
    prev_year: {
      label: "L'année précédente",
      range: [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')]
    }
  }
}