import { Controller } from "@hotwired/stimulus"
import { ajaxPostTurboStream } from 'shared/ajax_turbo_stream';

// Connects to data-controller="admin--dashboard--commercials-performance"
export default class extends Controller {
  connect() {
    this.reload()
    $(this.element).find('select').on('select2:select', () => {this.reload()})
  }

  reload() {
    $(this.element).addClass('loading-card')
    let params = {}
    if($(this.element).find('select').val() != '_') {
      params.prospectInboundTypeId = $(this.element).find('select').val()
    }
    ajaxPostTurboStream(
      '/admin/dashboard/load_commercials_performance',
      params,
      () => {$(this.element).removeClass('loading-card')},
      false
    )
  }
}