import IndexPages__GenericIndexTableController from "../generic_index_table_controller"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="index-pages--admin--prospect-index-table"
export default class extends IndexPages__GenericIndexTableController {
  editTemperature(e){
    initModal()
    $('.modal .modal-dialog .modal-body').html(this.populateTemperatures($(e.currentTarget).data().temperature))
    $('.modal-title').text("Modification de la température")
    $('#modal_rails_5').modal('show')
    $('.modal-dialog').addClass('modal-l')
    $('.modal-footer').hide()
    this.temperature_element = $(e.currentTarget)
  }

  newCommercialAction(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}`)
  }

  populateTemperatures(temperature){
    let html = `<div class=" align-items" style="justify-content: space-around;" id="prospect_background">`
    $.each (this.colors, function(key, val){
      html += `
        <div class="prospect_square_temperature cursor-pointer" data-temperature="${key}">
          <div class="${val.style}"></div>
          <p>${val.name}</p>
        </div>
      `
    })
    html += `</div>`
    return html
  }

  callProspect(e) {
    ajaxTurboStream(`/admin/commercial_actions/new?prospect_id=${parseInt($(e.currentTarget).parents('tr').eq(0).data().id)}&outgoing_phone_call=true`)
  }
}
