import IndexPages__GenericIndexPageController from "../generic_index_page_controller"
import { ajaxTurboStream } from 'shared/ajax_turbo_stream';
// Connects to data-controller="index-pages--admin--prospect-index-page"
export default class extends IndexPages__GenericIndexPageController {
  customConnect() {
    this.checkStatuses()
    $(this.element).find('#index-page-filters__input__status').on("change", (e) => {
      this.checkStatuses()
    })

    this.initSearch()

    this.colors = {
      'no_information': {style: 'bg-transparent' ,name: "Pas d’information (+10%)"},
      'will_sign': {style: 'bg-red', name: 'Information qui signe chez nous (+90%)'},
      'interested_by_product': {style: 'bg-orange', name: 'Très intéressé par le produit (+60%)'},
      'undecided': {style: 'bg-yellow', name: 'Incertain, en recherche active (+30%)'},
      'not_interested': {style: 'bg-lightblue', name: 'Pas intéressé dû au produit TopOrder (+2%)'},
      //'lost': {style: 'bg-lost', name: 'Perdu (0%)'}
    }

    $(document).on('click', '.prospect_square_temperature', (p) => {
      let new_temperature = $(p.currentTarget).data().temperature

      let field_temperature = this.temperature_element.parent()

      $.ajax({
        method: 'PUT',
        data: {temperature : new_temperature},
        url: '/admin/prospects/'+this.temperature_element.data().id+'/update_temperature'
      }).done(() => {
        field_temperature.removeClass('bg-red bg-orange bg-yellow bg-lightblue bg-lost bg-transparent')
        field_temperature.addClass(this.colors[new_temperature.toString()].style)
        this.temperature_element.attr('data-temperature', new_temperature);
        $('.modal').modal('hide')
      })
    })
  }

  initSearch() {
    $( "#prospect_index_search" ).autocomplete({
      minLength: 3,
      delay: 300,
      source: ( request, response ) => {
        if(request.term.trim().length == 0) { return false; }
        let term = new URLSearchParams({term: request.term.toLowerCase(), suspect: window.location.href.includes('suspect')}).toString()
        $.get(`/admin/prospects/live_autocomplete?${term}`).done(data => {
          response(data.prospects.filter((prospect, index) => {
            return true
          }).slice(0, 15))
        })
      },
      select: function( event, ui ) {
        Turbo.visit(`/admin/prospects/${ui.item.id}/edit`)
        return false;
      }
    })
  }

  checkStatuses() {
    $(this.element).find('#index-page-filters__input__prospect_commercial_step_id').parent().toggle(
      !$('#index-page-filters__input__status').val().includes('signed')
    )
  }

  toggleCheckbox(e) {
    $(e.currentTarget).find('input').prop('checked', !$(e.currentTarget).find('input').prop('checked'))
  }

  toggleAll() {
    $('.data-table-rows-check input').prop('checked', true)
  }

  unToggleAll() {
    $('.data-table-rows-check input').prop('checked', false) 
  }

  applyBatchAction() {
    console.log('bouhhh')
    let selected_ids = $(this.element).find('tbody :checked').map((i, e) => {return $(e).parents('tr').data().id}).get()
    if(selected_ids.length == 0) {
      return false
    }

    switch($('#prospects_batch_actions_container select').val()) {
      case 'archive':
        $.ajax({
          url: '/admin/prospects/archive_batch',
          method: 'PATCH',
          data: {prospect_ids: selected_ids}
        }).done(() => {
          this.data_filter()
          flashMessage('', "Les prospects ont été archivés")
        })
        break;
      case 'destroy':
        $.ajax({
          url: '/admin/prospects/destroy_batch',
          method: 'PATCH',
          data: {prospect_ids: selected_ids}
        }).done(() => {
          this.data_filter()
          flashMessage('', "Les prospects ont été supprimés")
        })
        break;
      case 'transfer':
        ajaxTurboStream(`/admin/prospects/new_batch_transfer?prospect_ids=${selected_ids.join('_')}`)
        break;
      case 'commercial_action':
        ajaxTurboStream(`/admin/commercial_actions/new_batch?prospect_ids=${selected_ids.join('_')}`)
        break;
      default:
    }
  }
}
